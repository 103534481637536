body {
  background-image: url(./assets/logo.png);
  background-size: 100%;
background-repeat: no-repeat;
font-family: 'Kaushan Script', cursive;
font-weight: bold;
}

h1 {
  color: orange;
}

.pointable:hover {
  cursor: pointer;
  color: red;
}

.placebid:hover {
  transform: scale(1.1);
}

.image-container {
  position: relative;
  display: inline-block;
}

.button-hidden {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.image-container:hover .button-hidden {
  display: block;
}

/* ChatBox.css */
.chat-container {
  width: 300px;
  height: 400px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.messages {
  flex: 1;
  overflow-y: scroll;
}

.input-area {
  margin-top: 10px;
}

.input-area input {
  width: 80%;
  padding: 8px;
}

.input-area button {
  width: 18%;
  padding: 8px;
  margin-left: 2%;
}

/* ChatBox.css (updated part) */
.user, .bot {
  padding: 8px 10px;
  margin: 5px;
  border-radius: 8px;
  max-width: 80%;
}

.user {
  align-self: flex-end;
  background-color: #e6f7ff;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.bot {
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  margin: 0 5px;
}
